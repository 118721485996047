import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { WebsocketService } from 'src/app/services/websocket/websocket.service';
import { GeneralService } from 'src/app/services/general/general.service';
import { ActivatedRoute } from '@angular/router';
import icons from 'src/assets/data/icons';
import { InfoDialogComponent } from 'src/app/components/info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent implements OnInit, OnDestroy {

  title = 'campusbots';
  public showSupportButton: any = true;
  public user: any;
  public userInfo: any;
  public subscriptions = new Array();

  constructor(
    translate: TranslateService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private websocketService: WebsocketService,
    private general: GeneralService,
    private cdRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    public dialog: MatDialog
  ) {

    let lang = localStorage.getItem('lang');
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('es');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    if (lang) {
      translate.use(lang);
    } else {
      translate.use('es');
      localStorage.setItem('lang', 'es');
      localStorage.setItem('locale', 'es-es');
    }

    this.registerSvg();
  }

  async ngOnInit() {
    await this.initSubscriptions();
    window.addEventListener('hide:support', this);
    window.addEventListener('show:support', this);
    window.addEventListener('exec:load-user-init-data', this);
    window.addEventListener('app:new-message', this);
    await this.loadInitData();
  }

  ngOnDestroy() {
    window.removeEventListener('hide:support', this);
    window.removeEventListener('show:support', this);
    window.removeEventListener('exec:load-user-init-data', this);
    window.removeEventListener('app:new-message', this);
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  // Handle event Listener
  async handleEvent(event: any) {
    
    switch (event.type) {
      case 'hide:support':
        this.showSupportButton = false;
        break;
      case 'show:support':
        this.showSupportButton = true;
        break;

      case 'exec:load-user-init-data':
        await this.getUserInfo();
        const functions = [this.loadUserInitData()];
        if (this.user?.rol === 'teacher') {
          functions.push(this.loadTeacherInitData());
        } else if (this.user?.rol === 'student') {
          functions.push(this.loadStudentInitData());
        }
        await Promise.all(functions);
        break;

      case 'app:new-message':
        await this.showNewMessageAction(event.detail);
        break;
    }
  }

  openSupport() {
    //  window.location.href = 'mailto:soporte@campusbots.es';
    window.location.href = 'https://campusbots.es/soporte';
  }

  registerSvg() {
    icons.forEach((icon) => {
      this.matIconRegistry.addSvgIcon(
        icon.name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(icon.url)
      );
    });
  }

  async initSubscriptions() {

    this.subscriptions = [

      this.general.user$?.subscribe(async (res: any) => {
        if (!res) {
          return;
        }
        if (res.chat_token !== this.user?.chat_token) {
          this.websocketService.initSocket(res.chat_token);
        }
        //this.user = res;
      }),
      this.route.queryParams.subscribe(async (data) => {
        if (data.lang) {
          await this.onChangeLang(data.lang, false);
        }
      }),
    ];

    
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  async onChangeLang(event: any, reload = true) {
    this.general.translate.use(event);
    localStorage.setItem('lang', event);
    this.setLocale(event);
    let params: any = await this.general.getQueryParams();
    if (params.lang) {
      let body = { ...params };
      body.lang = null;
      this.general.updateQueryParams(body);
    }
    if (reload) {
      // window.location.reload();
    }
    if (this.user) {
      await this.general.httpPost('update-language', {
        default_lang: event,
      });
    }
    await this.general.sleep(100);
    window.dispatchEvent(new CustomEvent('set-lang', { detail: event }));
  }

  setLocale(lang: string) {
    switch (lang) {
      case 'es':
        localStorage.setItem('locale', 'es-es');
        break;
      case 'en':
        localStorage.setItem('locale', 'en-gb');
        break;
      case 'ru':
        localStorage.setItem('locale', 'ru');
        break;
    }
  }

  async loadInitData() {
    const functions = [];

    if (this.user) {
      functions.push(this.loadUserInitData());
    }

    if (this.user?.rol === 'teacher') {
      functions.push(this.loadTeacherInitData());
    } else if (this.user?.rol === 'student') {
      functions.push(this.loadStudentInitData());
    }

    functions.push(this.loadGuestInitData());

    await Promise.all(functions);
  }

  async loadUserInitData() {
    const requests = [this.general.httpGet('chat/no-read-messages')];
    const response: any = await this.general.forkJoin(requests);
    this.checkNoReadMessages(response[0].data);
  }

  async loadTeacherInitData() {
    const requests = [this.general.httpGet('wallet-available')];
    const response: any = await this.general.forkJoin(requests);
    this.setWalletTeacher(response[0].data);
  }

  async loadStudentInitData() {
    const requests = [this.general.httpGet('student-hours')];
    const response: any = await this.general.forkJoin(requests);
    this.setWalletStudent(response[0].data);
  }

  async getUserInfo() {
    const response = await this.general.getUserInfo();
    this.userInfo = response.data;
    this.user = this.userInfo.user;
  }
  
  async loadGuestInitData() { }

  checkNoReadMessages(data: any) {
    this.general.setNoReadMessages(data);
  }

  async setWalletTeacher(data: any) {
    this.general.setWallet(`${data} €`);
  }
  async setWalletStudent(data: any) {
    const hours = await this.general.getTranslations('hours');
    this.general.setWallet(`${data} ${hours}`);
  }

  async showNewMessageAction(event: any) {

    const response = await this.general.httpGet('chat/load-chat-list');
    let chatsTemporal = response.data;
    let notReadChatsCountTemporal = 0;
    chatsTemporal.forEach((chat: any) => {
      notReadChatsCountTemporal += chat.count;
    });
    localStorage.setItem('noReadChats', JSON.stringify(notReadChatsCountTemporal));

    /*
    console.log('app.component',event);
    //Mostrar el dialog con el event
    this.dialog.open(InfoDialogComponent, {
      disableClose: true,
       data: event,
    });
    */
    // Mostrar puntito rojo en la pestaña de mensajes
  }
}
